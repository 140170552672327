<template>
  <div>
    <CContainer fluid>
      <IkoCartas
        :cartas="cartas"
        name="prop"
        :loading="loading"
        @criarModal="criarModal"
        @deleteCarta="eliminarCarta"
        @editarModal="editarModal"
      ></IkoCartas>
    </CContainer>

    <CModal
      :title="`${modal.type} prop`"
      :color="modal.type === 'Edit' ? 'warning' : 'success'"
      :show.sync="modal.open"
    >
      <CCol sm="12">
        <CInput
          v-model="form.name"
          label="Name"
          placeholder="Prop Name"
        />
      </CCol>

      <template #footer>
        <CButton @click="modal.open = false" color="danger">Cancel</CButton>
        <CButton @click="modalSubmit" :disabled="aSubmeter" color="success">Submit</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { getProps, putProp, deleteProp } from '@/api/props';
import IkoCartas from '@/components/IkoCartas.vue';
export default {
  name: 'Props',
  components: {
    IkoCartas,
  },
  data: () => ({
    modal: {
      type: 'Edit',
      open: false
    },
    form: {},
    aSubmeter: false,
    loading: true,
    cartas: []
  }),
  methods: {
    editarModal(idx) {
      this.form = {...this.cartas[idx].data};
      this.modal.type = 'Edit';
      this.modal.open = true;
    },
    criarModal() {
      this.form = {};
      this.modal.type = 'New';
      this.modal.open = true;
    },
    addCarta(carta) {
      this.cartas.push({
        data: carta,
        header: carta.name,
        text: `<b>Products</b>: ${carta?.products?.length ?? 'unknown'}`,
        to: [{ display: 'Products', name: 'PropProducts', params: { prop: carta.name }}]
      });
    },
    modalSubmit() {
        this.aSubmeter = true;
        if (this.modal.type === 'Edit') {
          putProp(this.form.name, this.form).then(() => {
              this.carregarCartas();
              this.modal.open = false;
              this.form = {};
              this.aSubmeter = false;
          }).catch(({ message }) => {
              alert(message);
          });
        }
    },
    eliminarCarta(idx) {
      deleteProp(this.cartas[idx].data.name).then(() => {
        this.cartas.splice(idx, 1);
        this.aSubmeter = false;
        this.eliminarModal = false;
      });
    },
    carregarCartas() {
      this.loading = true;
      this.cartas = [];
      getProps(['products']).then((props) => {
        props = props.sort((a, b) => a.name - b.name);
        props.forEach(prop => this.addCarta(prop));
      }).finally(() => this.loading = false);
    },
  },
  mounted() {
    this.carregarCartas();
  }
}
</script>
