import request from '@/utils/request';

export function getProps(options) {
  return request({
    url: `/props${options !== undefined ? `?options${options.length ? `=${options.join(encodeURIComponent('+'))}` : ''}` : ''}`,
    method: 'get',
  });
}

export function postProp(data) {
  return request({
    url: '/props',
    method: 'post',
    data
  })
}

export function putProp(id, data) {
  return request({
    url: `/props/${id}`,
    method: 'post',
    data
  })
}

export function deleteProp(id) {
  return request({
    url: `/props/${id}`,
    method: 'delete',
  })
}